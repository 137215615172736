<template>
  <v-card>
    <v-subheader>{{ label }}</v-subheader>

    <v-card-text>
      <v-rating
        dense
        length="5"
        size="36"
        :value="defaultRating"
        @input="sendRating"
        :readonly="readonly"
      ></v-rating>
      <div>
        <span class="caption text-uppercase">回答:</span>
        <span class="font-weight-bold">
          {{ ticksLabels[defaultRating] }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Rank",
  props: ["label", "id", "defaultRating", "readonly"],
  data() {
    return {
      value: 0,
      // rating: this.defaultRating,
      ticksLabels: ["未回答", "不満", "やや不満", "普通", "満足", "非常に満足"]
    };
  },
  methods: {
    sendRating(v) {
      this.$emit("rating", this.id, v);
    }
  }
};
</script>
