<template>
  <div>
    <v-card
      class="mx-auto"
      elevation="8"
      :height="cardHeight()"
      v-if="viewType() === 'register'"
    >
      <v-list-item three-line class="my-0 py-0">
        <v-list-item-content class="md-0">
          <v-list-item-title class="headline mb-1">
            {{ classroom.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="!classroom.parentCourseCode">
            本科目コード: {{ classroom.courseCode }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="classroom.parentCourseCode">
            特別コード: {{ classroom.courseCode }} / 本科目コード:
            {{ classroom.parentCourseCode }}
          </v-list-item-subtitle>
          <v-card-text class="card-text py-0" v-if="!inActive">
            <v-chip class="ma-0" color="accent">
              {{ classroom.courseCode }}
            </v-chip>
            <v-chip class="ma-0" color="accent">
              {{ classroom.className }}
            </v-chip>
            <v-chip class="ma-0" color="accent">
              {{ classroom.teacherName }}先生 </v-chip
            ><br />
            {{ classroom.section }}
            {{ classroom.name }}&nbsp; {{ classroom.room }}
            <v-tooltip top v-if="hasEnrolled">
              <template v-slot:activator="{ on, attrs }">
                <v-chip class="ma-1" label x-small v-bind="attrs" v-on="on">
                  <span class="enrollmentCode" @click="copyEnrollmentCode">{{
                    classroom.enrollmentCode
                  }}</span>
                  <v-icon right small @click="copyEnrollmentCodeByButton">
                    mdi-content-copy
                  </v-icon>
                </v-chip>
              </template>
              <span v-if="!copiedMessage">クリックして招待リンクをコピー</span>
              <span v-if="copiedMessage">{{ copiedMessage }} </span>
            </v-tooltip>
          </v-card-text>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions v-if="!inActive" class="ma-0 pa-0">
        <v-spacer></v-spacer>
        <v-dialog v-if="hasEnrolled & canResearch" v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              class="ma-1 pa-1"
              :color="btnColor"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <span v-if="!hasResearch">CS調査回答</span
              ><span v-if="hasResearch">CS調査回答済み</span>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="headline grey lighten-2">
              <span v-if="!hasResearch">CS調査回答</span
              ><span v-if="hasResearch">CS調査回答済み確認</span>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                閉じる
              </v-btn>
            </v-card-title>
            <v-card-text>
              <FormMain :classroom="classroom" @dialog="dialog = false" />
            </v-card-text>

            <v-divider></v-divider>
          </v-card>
        </v-dialog>

        <v-btn
          v-if="canEnroll"
          small
          class="ma-1 pa-1"
          color="secondary"
          @click="enroll"
          >登録</v-btn
        >
        <v-dialog
          v-model="cancelDialog"
          v-if="canUnEnroll"
          persistent
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              class="ma-1 pa-1"
              color="blue-grey"
              v-bind="attrs"
              v-on="on"
              >登録解除</v-btn
            >
          </template>
          <v-card>
            <v-card-title class="headline">
              Google Classroomへの登録を取り消しますか?
            </v-card-title>
            <v-card-text>
              ここで登録を解除しても履修の取り消しにはなりません。
              履修登録の変更または取り消しは担任の先生に確認をしてください。
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="cancelDialog = false">
                キャンセル
              </v-btn>
              <v-btn color="green darken-1" text @click="unenroll">
                登録解除の実行
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-if="cannotEnroll" x-small class="ma-1 pa-1" color="grey"
          >登録不可</v-btn
        >
        <v-btn v-if="cannotUnEnroll" x-small class="ma-1 pa-1" color="grey"
          >解除不可</v-btn
        >
        <v-btn
          v-if="canAssignedTeacher"
          small
          class="ma-1 pa-1"
          color="secondary"
          @click="assignTeacher"
          >講師登録</v-btn
        >
        <v-btn
          v-if="canUnAssignedTeacher"
          x-small
          class="ma-1 pa-1"
          color="blue-grey"
          @click="unAssignTeacher"
          >講師解除</v-btn
        >
        <v-btn
          v-if="classroom.inviteUrl"
          x-small
          class="ma-1 pa-1"
          color="indigo accent-1"
          :href="classroom.inviteUrl"
          target="_blank"
          :disabled="!hasEnrolled"
          >Classroom</v-btn
        >
        <v-btn
          v-if="isAttendanceCourse"
          small
          class="ma-1 pa-1"
          color="primary"
          :to="getAttendancePath()"
          :disabled="!hasEnrolled && !hasTeachCourse && !hacCoordinator"
          >出欠</v-btn
        >
      </v-card-actions>
      <v-card-text v-if="!inActive" class="pa-0">
        <ClassroomBatch
          :length="numberOf"
          :page="pagination"
          @pagination="setPagination"
        />
      </v-card-text>
    </v-card>
    <v-card
      class="mx-auto"
      :elevation="elevation"
      :height="cardHeight()"
      v-if="viewType() === null"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1" v-if="!inActive">
            空き時間
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>
<script>
import FormMain from "./Form/Main.vue";
import ClassroomBatch from "./ClassroomBatch";

export default {
  name: "Classroom",
  props: ["classrooms", "view", "inActive", "numberOf"],
  components: { FormMain, ClassroomBatch },
  data: () => ({
    copiedMessage: null,
    dialog: false,
    pagination: 1,
    cancelDialog: false
  }),
  computed: {
    canResearch() {
      return this.$store.state.canResearch & this.classroom.isCS;
    },
    hasResearch() {
      const courseCode = this.classroom.courseCode;
      const submitted = this.$store.state.submittedList;
      // return submitted.has(courseCode);
      const index = submitted.findIndex(s => {
        return s.courseId === courseCode;
      });
      return index > -1;
    },
    hasEnrolled() {
      const enrolled = this.$store.state.enrolledList;
      const courseCode = this.classroom.courseCode;
      const parentCourseCode = this.classroom.parentCourseCode;
      const index = enrolled.findIndex(s => {
        return s.courseCode === courseCode || s.courseCode === parentCourseCode;
      });
      return index > -1;
    },
    hasParentCourseCode() {
      return !!this.classroom.parentCourseCode;
    },
    hasCoordinatorTeacher() {
      const userType = this.$store.state.userType;
      if (userType === "coordinator" || userType === "teacher") {
        return true;
      } else {
        return false;
      }
    },
    hacCoordinator() {
      const userType = this.$store.state.userType;
      if (userType === "coordinator") {
        return true;
      } else {
        return false;
      }
    },
    hasTeachCourse() {
      if (this.hasCoordinatorTeacher) {
        const teachCourses = this.$store.state.teachCourseList;
        const courseCode = this.classroom.courseCode;
        const parentCourseCode = this.classroom.parentCourseCode;
        const index = teachCourses.findIndex(c => {
          return (
            c.courseCode === courseCode || c.courseCode === parentCourseCode
          );
        });
        return index > -1;
      } else {
        return false;
      }
    },
    isAttendanceCourse() {
      return this.classroom.attendance;
    },
    canEnroll() {
      if (this.hasCoordinatorTeacher) {
        return false;
      } else {
        return !!(!this.hasParentCourseCode && !this.hasEnrolled);
      }
    },
    canUnEnroll() {
      if (this.hasCoordinatorTeacher) {
        return false;
      } else {
        return !!(!this.hasParentCourseCode && this.hasEnrolled);
      }
    },
    cannotEnroll() {
      if (this.hasCoordinatorTeacher) {
        return false;
      } else {
        return !!(this.hasParentCourseCode && !this.hasEnrolled);
      }
    },
    cannotUnEnroll() {
      if (this.hasCoordinatorTeacher) {
        return false;
      } else {
        return !!(this.hasParentCourseCode && this.hasEnrolled);
      }
    },
    canAssignedTeacher() {
      if (this.hasCoordinatorTeacher) {
        return !this.hasTeachCourse;
      } else {
        return false;
      }
    },
    canUnAssignedTeacher() {
      if (this.hasCoordinatorTeacher) {
        return this.hasTeachCourse;
      } else {
        return false;
      }
    },
    btnColor() {
      const courseCode = this.classroom.courseCode;
      const submitted = this.$store.state.submittedList;
      const index = submitted.findIndex(s => {
        return s.courseId === courseCode;
      });
      // if (submitted.has(courseCode)) {
      if (index > -1) {
        return "blue-grey";
      } else {
        return "secondary";
      }
    },
    elevation() {
      return this.inActive ? 4 : 12;
    },
    classroom() {
      if (this.classrooms !== null) {
        return this.classrooms[this.pagination - 1];
      } else {
        return null;
      }
    }
  },
  methods: {
    cardHeight() {
      return this.$store.state.cardHeight;
    },
    viewType() {
      if (this.classroom !== null) {
        if (this.view) {
          return this.view;
        } else {
          return "register";
        }
      } else {
        return null;
      }
    },
    enroll() {
      const parentCourseCode = this.classroom.parentCourseCode;
      if (parentCourseCode) {
        this.$store.dispatch("doEnroll", parentCourseCode);
      } else {
        this.$store.dispatch("doEnroll", this.classroom.courseCode);
      }
    },
    unenroll() {
      this.cancelDialog = false;
      this.$store.dispatch("doUnEnroll", this.classroom.courseCode);
    },
    assignTeacher() {
      const parentCourseCode = this.classroom.parentCourseCode;
      if (parentCourseCode) {
        this.$store.dispatch("doAssignTeacher", parentCourseCode);
      } else {
        this.$store.dispatch("doAssignTeacher", this.classroom.courseCode);
      }
    },
    unAssignTeacher() {
      this.$store.dispatch("doUnAssignTeacher", this.classroom.courseCode);
    },
    setPagination(value) {
      this.pagination = value;
    },
    copyEnrollmentCode(e) {
      document.getSelection().selectAllChildren(e.target);
      document.execCommand("copy");
      this.showCopiedMessage();
    },
    copyEnrollmentCodeByButton(e) {
      document
        .getSelection()
        .selectAllChildren(e.target.previousElementSibling);
      document.execCommand("copy");
      this.showCopiedMessage();
    },
    async showCopiedMessage() {
      const _sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
      this.copiedMessage = `招待コード 【${this.classroom.enrollmentCode}】がコピーされました`;
      await _sleep(3000);
      this.copiedMessage = null;
    },
    getAttendancePath() {
      if (this.classroom.parentCourseCode) {
        return `/attendance/${this.classroom.parentCourseCode}/today`;
      } else {
        return `/attendance/${this.classroom.courseCode}/today`;
      }
    }
  }
};
</script>
<style scoped>
.headline {
  font-size: 1.3rem !important;
}
.card-text {
  font-size: 0.8rem;
}
</style>
