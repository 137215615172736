<template>
  <v-pagination
    v-if="length > 1"
    circle
    @input="setPage"
    :value="page"
    :length="length"
    :total-visible="5"
  ></v-pagination>
</template>
<script>
export default {
  name: "ClassroomBatch",
  props: ["length", "page"],

  data() {
    return {};
  },
  computed: {},
  methods: {
    setPage(p) {
      this.$emit("pagination", p);
    }
  }
};
</script>
