<template>
  <v-container>
    <v-form ref="forms">
      <h2 class="headline mb-1">
        {{ classroom.name }}
      </h2>
      <div>
        <v-chip class="ma-2" color="accent">
          {{ classroom.courseCode }}
        </v-chip>
        <v-chip class="ma-2" color="accent"> {{ classroom.className }} </v-chip
        ><br />
        {{ classroom.section }}
      </div>
      <v-row v-for="rank in rankResearches" :key="rank.id">
        <v-col>
          <Rank
            :label="rank.label"
            :id="rank.id"
            :defaultRating="rank.value"
            @rating="receiveRank"
            :readonly="hasResarch"
          />
        </v-col>
      </v-row>
      <v-row>
        <TextMessage
          :label="text['message'].label"
          :id="text['message'].id"
          :defaultMessage="text['message'].value"
          @message="receiveMessage"
          :readonly="hasResarch"
        />
      </v-row>
      <v-row>
        <v-col>
          <v-card-actions>
            <span v-if="errorMessage">{{ errorMessage }}</span>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              color="secondary"
              @click="submit"
              :disabled="hasResarch"
              >回答の送信</v-btn
            ></v-card-actions
          ></v-col
        >
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import Rank from "./Rank.vue";
import TextMessage from "./Text.vue";

export default {
  name: "FormMain",
  props: ["classroom"],
  data: () => ({
    errorMessage: null,
    rankResearches: {
      select1: { id: "select1", label: "授業内容に対する満足度", value: 0 },
      select2: { id: "select2", label: "教師に対する満足度", value: 0 },
      select3: { id: "select3", label: "あなた自身の努力度", value: 0 }
    },
    text: {
      message: {
        id: "message",
        label:
          "授業内容や教え方について、どう思いますか。自由に書いてください。",
        value: null
      }
    }
  }),
  components: {
    Rank,
    TextMessage
  },
  computed: {
    hasResarch() {
      const courseCode = this.classroom.courseCode;
      const submitted = this.$store.state.submittedList;
      // return submitted.has(courseCode);
      const index = submitted.findIndex(s => {
        return s.courseId === courseCode;
      });
      return index > -1;
    },
    select1Value() {
      const codeSubmitted = this.sbumittedByCode();
      return codeSubmitted ? codeSubmitted.select1 : 0;
    },
    select2Value() {
      const codeSubmitted = this.sbumittedByCode();
      return codeSubmitted ? codeSubmitted.select2 : 0;
    },
    select3Value() {
      const codeSubmitted = this.sbumittedByCode();
      return codeSubmitted ? codeSubmitted.select3 : 0;
    },
    messageValue() {
      const codeSubmitted = this.sbumittedByCode();
      return codeSubmitted ? codeSubmitted.message : null;
    }
  },
  mounted() {
    this.rankResearches.select1.value = this.select1Value;
    this.rankResearches.select2.value = this.select2Value;
    this.rankResearches.select3.value = this.select3Value;
    this.text.message.value = this.messageValue;
  },
  methods: {
    sbumittedByCode() {
      const courseCode = this.classroom.courseCode;
      const submitted = this.$store.state.submittedList;
      // return submitted.get(courseCode);
      const index = submitted.findIndex(s => {
        return s.courseId === courseCode;
      });
      return submitted[index];
    },
    receiveRank(rankId, value) {
      this.errorMessage = null;
      this.rankResearches[rankId].value = value;
    },
    receiveMessage(textId, value) {
      this.text[textId].value = value;
    },
    submit() {
      // TODO: 必須処理
      let errors = [];
      if (this.$refs.forms.validate()) {
        let answer = {};
        Object.keys(this.rankResearches).map(key => {
          answer[key] = this.rankResearches[key].value;
          if (answer[key] < 1) {
            errors.push(key);
          }
        });
        Object.keys(this.text).map(key => {
          answer[key] = this.text[key].value;
          if (answer[key] == null) {
            errors.push(key);
          }
        });
        if (errors.length === 0) {
          const data = {
            courseCode: this.classroom.courseCode,
            answer: answer
          };
          this.$store.dispatch("doSubmit", data);
          this.$emit("dialog", false);
        } else {
          this.errorMessage = "入力エラーがありました";
        }
      } else {
        console.log("No commit");
      }
    }
  }
};
</script>
