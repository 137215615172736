<template>
  <v-textarea
    outlined
    :name="id"
    :label="label"
    :value="defaultMessage"
    @input="sendText"
    :readonly="readonly"
    :rules="[required]"
    required
  ></v-textarea>
</template>
<script>
export default {
  name: "TextMessage",
  props: ["label", "id", "defaultMessage", "readonly"],
  data() {
    return {
      required: value => !!value || "必須入力"
    };
  },
  methods: {
    sendText(v) {
      this.$emit("message", this.id, v);
    }
  }
};
</script>
